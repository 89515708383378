@charset "utf-8";

@import "./main.css";
@import "./bootstrap.min.css";
@import "./animate.css";
@import "./color-switcher.css";
@import "./flaticon.css";
@import "./font-awesome.css";

@import "./shortcodes.css";
@import "./themify-icons.css";
@import "./colors/theme-color2.css";
// COLORS
// $primary: #00d1b2;
// $info: #209CEE;
// $success: #23D160;
// $warning: #FFDD57;
// $danger: #FF3860;
// $light: #F5F5F5;
// $dark: #363636;

// TEXT
// $text: #4A4A4A;
// $link: #3273DC;
// $body-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
//   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//   "Helvetica", "Arial", sans-serif;
// $title-family: false;
// $button-family: false;

// BREAKPOINTS
// $gap: 32px;
// $tablet: 769px;
// $desktop: 960px + (2 * $gap);
// $widescreen: 1152px + (2 * $gap);
// $fullhd: 1344px + (2 * $gap);
// $widescreen-enabled: true;
// $fullhd-enabled: false;

// LAYOUT
// $section-padding: 3rem 1.5rem;
// $section-padding-medium: 6rem 1.5rem;
// $section-padding-large: 9rem 1.5rem;

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA
// @import "~bulma/bulma.sass";

// IMPORT FONT AWESOME
// @import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");

h1, h2, h3, h4, h5, h6{
  color: #182333;
}
body{
  font-family: poppins;
  font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #8d9297;
}
a{
  color:#182333;
}
a:hover{
  text-decoration: none;
}
.main-slider-section{
  background-image: url('../images/main-slide.jpg');
  background-size: cover;
  padding: 70px 0px;
  .row{
    .sub-title{
      p{
        line-height: 20px;
        letter-spacing: 3px;
        font-weight: 200;
        font-size: 18px;
        color: rgb(242, 244, 248);
        text-transform: uppercase;
      }
    }
    .main-title{
      h1{
        line-height: 100px;
        letter-spacing: 0px;
        font-weight: 600;
        font-size: 75px;
        color: rgb(242, 244, 248);
        text-transform: none;
        span{
          color: #FD5E14;
        }
      }
    }
    .description{
      padding: 20px 0px;
      ul{
        list-style: none;
        padding: 0px;
        display: flex;
        align-items: center;
        li{
          color: #fff;
          font-size: 22px;
        }
        li:after{
          content: '•';
          padding: 0px 10px;
          color: #FD5E14;
        }
        li:last-child:after{
          content: none; 
        }
      }
    }
    .main-button{
      a{
        padding: 10px 20px;
        background:#FD5E14;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .slider-form{
    background: #fff;
    padding: 30px;
    .slider-btn{
      width: 100%;
      background:#FD5E14;
      color: #fff;
      font-weight: bold;
    }
  }
}

.ReactGridGallery_tile{
	margin: 0px !important;
	background: none !important;
	@media(min-width:767px){
	width: 33%;
	}
	.ReactGridGallery_tile-viewport{
		height: auto !important;
		img{
			width: 100%;
			height: auto !important;
			padding: 10px 10px;
			border-radius: 15px;

		}
	}
}

.second-footer{
  .widget-area{
    text-align: center;
    .cal-lic{
      h6{
        text-transform: uppercase;
        letter-spacing: 2px;
        span{
          color: #fd5e14;
        }
      }
    }
    .footer-links{
      ul{
        display: flex;
        justify-content:center;
        li:after{
          content:'|';
          padding: 0px 10px;
        }
        li:last-child:after{
          content:none;
        }
      }
    }
    .footer-call-links{
      ul{
        display: flex;
        justify-content:center;
        li{
          a{
            font-size: 18px;
            font-weight: bold;
          }
        }
          li:after{
            content:'|';
            padding: 0px 10px;
          }
          li:last-child:after{
            content:none;
          }
        
        
      }
    }
  }
}

.col-bg-img-three.ttm-col-bgimage-yes{
  .ttm-col-wrapper-bg-layer{
    background-image: url('../images/cta-bg.jpg') !important;
  }
} 
.bg-img5{
  background-image: url('../images/main-cta.jpg');
  background-position: bottom center;
}
.ttm-social-links-wrapper{
  .lic-number{
    p{
      margin-bottom: 0px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 2px;
      font-size: 17px;
    }
  }
}

.ttm-topbar-content{
  .top-contact{
    a:hover{
      color: #fff;
    }
    .tel-no{
      font-weight: bold;
      color: #fff;
      letter-spacing: 1px;
      font-size: 16px;
      margin-left: 5px;
    }
  }
}
#menu{
  @media(min-width:767px){
    height: 115px;
    line-height: 115px;
  }
}
.fixed-header{
  #menu{
    @media(min-width:767px){
      height: auto;
      line-height: inherit;
    }
  }
}
.site-branding{
  img{
    max-height: 60px;
  }
}
.footer-logo{
  img{
    max-height: 70px;
  }
}

#process{
  .ttm-processbox-wrapper{
    @media(max-width:767px){
      display: block;
    }
    .ttm-processbox{
      @media(max-width:767px){
        width: 100%;
      }
      .ttm-box-image:before{
        @media(max-width:767px){
          display: none;
        }
      }
    }
  }
}
#get-a-quote{
  .section-title{
    .title{
      @media(max-width:767px){
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}

.main-slider-section{
  .main-title{
    h1{
      @media(max-width:767px){
        font-size: 40px !important;
        line-height: 50px !important;
      }
    }
  }
  .slider-form{
    @media(max-width:767px){
      margin-top: 30px;
    }
  }
}

.footer-links, .footer-call-links{
  ul{
    @media(max-width:767px){
      display: inline-block !important;
    }
    li{
      @media(max-width:767px){
        display: inline;
      }
    }
  }
}

.ttm-tabs{
  .tabs{
    @media(max-width:767px){
      display: flex;
    }
  }
}